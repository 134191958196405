import { useEffect, useState } from "react";
import { TrackGoogleAnalyticsEvent } from "../analytics/ga4Tracking";
import Form from "../form/form";
import { ReadOnlyProperties } from "../form/readOnly";
import { TextBox, TextBoxProperties } from "../form/textBox";
import { CodeExampleDownloadFormProperties } from "./properties/codeExampleDownloadFormProperties";


const CodeExampleDownloadForm: React.FC<CodeExampleDownloadFormProperties> = (codeExampleDownloadFormProperties: CodeExampleDownloadFormProperties) => {

    if (codeExampleDownloadFormProperties) {
        var gaEventParams: any = undefined;
        if (codeExampleDownloadFormProperties.ga4EventType) {
            gaEventParams = {"type": codeExampleDownloadFormProperties.ga4EventType};
        }


        return <div className="section-container">
        <div className="header">
            <h2>Download this code example</h2>
        </div>
        <div className="content">
            <span className="description">We will email you a link for this code example so you can download it onto your machine and try it for yourself.</span>
        <Form fieldProperties={
            [
                new TextBoxProperties({ name: "email", id: "code-example-free-form-email", label: "Email", placeholder: "Your email", htmlTag: "input", className: "form-group col-12",  required: true, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ig, requiredErrorMessage: <>Please enter your email address.</>, patternErrorText: <>Please enter a valid email address.</> }),
                new ReadOnlyProperties({ name: "umbracoNodeId", value: codeExampleDownloadFormProperties.umbracoNodeId })            
            ]
        } container={
            <>
                <TextBox name="email"></TextBox>
            </>
        } submitApiEndpoint="/api/code-example/download" submitLabel="Email download link" onSubmitCallback={(json) => {
            TrackGoogleAnalyticsEvent("code_example_download_form_submit", gaEventParams);
            TrackGoogleAnalyticsEvent('generate_lead', { 
                'generate_lead_form': 'Code example download form'            
            });
        }} thankYouContainer={
            <>
                Thank you.<br /><br />
                We have sent you an email with details on how to download the code example.<br /><br />
                If you don't receive the email in a few moments, please check your spam filter.
            </>
        }></Form>
        </div>
        </div>
    }
    return <></>
}

export default CodeExampleDownloadForm;